import React from "react";
import Helmet from "react-helmet";
import { BlackBoxText } from "components/BlackBoxText";
import { Content } from "components/Content";
import { Hero } from "components/Hero";
import { TextBlock } from "components/TextBlock";
import { List } from "components/List";
import { Page } from "components/Page";

function AboutPage(p) {
  return (
    <Page light={true} fixed={true} locationSearch={p.location.search}>
      <Helmet
        htmlAttributes={{
          lang: "en-GB",
        }}
        title={"About Us | Exeter Students' Guild Events"}
        meta={[
          {
            name: `description`,
            content:
              "We are your students’ union here at Exeter, and we exist to make your university experience as amazing as possible.",
          },
          {
            property: `og:url`,
            content: `https://events.exeterguild.com${p.location.pathname}`,
          },
          {
            property: `og:title`,
            content: "About Us | Exeter Students' Guild Events",
          },
          {
            property: `og:description`,
            content: ``,
          },
          {
            property: `og:image`,
            content: ``,
          },
          {
            name: "facebook-domain-verification",
            content: ``,
          },
        ]}
      />
      <Hero
        type="about"
        gradient={`linear-gradient(180deg, rgba(0, 0, 0, 0.42) 10.35%, rgba(0, 0, 0, 0) 24.11%)`}
      >
        <h1>ABOUT US</h1>
      </Hero>
      <Content centered>
        <List>
          <List size="small">
            <BlackBoxText size="small" align="center">
              About Exeter Students' Guild
            </BlackBoxText>
            <TextBlock>
              <p>
                “Everyone experiences university in their own unique way. There
                is no right or wrong way to experience it, your Students’ Guild
                is here to help you get the most of yours and to ensure you
                start university in the best way possible to suit you”. Lily
                Margaroli, Guild President
              </p>
            </TextBlock>
          </List>
          <List size="small">
            <BlackBoxText size="small" align="center">
              So, who are we?
            </BlackBoxText>
            <TextBlock>
              <p>
                We are your students’ union here at Exeter, and we exist to make
                your university experience as amazing as possible. We are led by
                you and all other Exeter students, with our Full-Time Officers
                representing your interests at the heart of everything we do.
                You are the Students’ Guild, so everything we do is for you.
              </p>
            </TextBlock>
          </List>
          <List size="small">
            <BlackBoxText size="small" align="center">
              Our Vision and Values
            </BlackBoxText>
            <TextBlock>
              <p>
                Our purpose is to help shape an excellent student experience for
                all students at Exeter by empowering them to take their place in
                the world and make a positive difference. We are a values driven
                organisation, with our 3 values being: radically inclusive,
                collaborative, and empowering.
              </p>
              <p>
                For more information download our{" "}
                <a
                  href="https://www.exeterguild.org/pageassets/about/guild-guide-digital.pdf"
                  target="_blank"
                  rel="noreferrer"
                >
                  guide.
                </a>
              </p>
            </TextBlock>
          </List>
        </List>
      </Content>
    </Page>
  );
}

export default AboutPage;
